import React from "react";

interface HeaderProps {
  leftImageUrl: string;
  rightImageUrl?: string;
}

const Header: React.FC<HeaderProps> = ({ leftImageUrl }) => {
  return (
    <div className="flex items-center justify-between w-full px-4">
      {/* Left Image */}
      <img
        src={leftImageUrl}
        alt="Left Logo"
        className="w-24 h-16 sm:w-32 sm:h-24 md:w-40 md:h-30 rounded-full cursor-pointer object-contain"
      />

      {/* Right Image */}
      {/* <img
        src={rightImageUrl}
        alt="Right Logo"
        className="hidden sm:block w-16 h-16 md:w-15 md:h-15 rounded-full cursor-pointer z-50"
      /> */}
    </div>
  );
};

export default Header;
