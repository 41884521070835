import React from "react";
import "./App.css";

import { LaunchScreen, PreLoaderScreen } from "./screens";

function App() {
  return (
    <>
      <div className="App">
        {/* <PreLoaderScreen /> */}
        <LaunchScreen />
      </div>
    </>
  );
}

export default App;
