// LaunchScreen Component
import React from "react";
import { Header, LaunchText, SocialMediaIcons } from "../../components";
import XcellifyLogoPrimary from "../../assests/images/xcellify-logo-primary.png";
import backgroundImage from "../../assests/images/background7.png";
import { iconsData } from "../../utils/constants";

const LaunchScreen: React.FC = () => {
  return (
    <div
      className="min-h-screen bg-cover bg-center bg-fixed bg-no-repeat flex flex-col justify-between"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        backgroundColor: "#000",
      }}
    >
      {/* Header at the top */}
      <div className="p-5 sm:p-10 md:p-15">
        <Header leftImageUrl={XcellifyLogoPrimary} />
      </div>

      {/* LaunchText and SocialMediaIcons at the bottom */}
      <div className="flex flex-col items-start p-5 sm:p-10 md:p-1 ml-10">
        <LaunchText
          text1="A Whole New World of Possibilities for Students!"
          text2="Coming"
          text3=" Soon . . ."
        />
        <SocialMediaIcons icons={iconsData} />
      </div>
    </div>
  );
};

export default LaunchScreen;
