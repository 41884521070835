import gsap from "gsap";

const tl = gsap.timeline();

export const preLoaderAnim = () => {
  tl.set(".texts-container", { opacity: 0 })
    .to("body", {
      duration: 0.1,
      css: { overflowY: "hidden" },
      ease: "power3.inOut",
    })
    .to(".landing", {
      duration: 0.05,
      css: { overflowY: "hidden", height: "90vh" },
    })
    .to(".texts-container", {
      duration: 0.1,
      opacity: 1,
      ease: "Power3.easeOut",
    })
    .from(".texts-container span", {
      duration: 1,
      delay: 0.1,
      y: 70,
      skewY: 10,
      stagger: 0.1,
      ease: "Power3.easeOut",
    })
    .to(".texts-container span", {
      duration: 0.1,
      y: 20,
      skewY: -10,
      opacity: 0,
      stagger: 0.1,
      ease: "Power3.easeOut",
    })
    .to(".landing", {
      duration: 0.05,
      css: { overflowY: "hidden", height: "unset" },
    })
    .to("body", {
      duration: 0.1,
      css: { overflowY: "scroll" },
      ease: "power3.inOut",
    })
    .to(".preloader", {
      duration: 1,
      height: "0vh",
      ease: "Power3.easeOut",
    })
    .set(".texts-container", {
      opacity: 0,
      css: { display: "none" },
    });
};
